form {
    label {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        margin-bottom: 7px;
    }
    .form-control {
        padding: 1.75rem 0.75rem;
    }
    .form-group {
        input {
            border-radius: 0 !important;
            outline: none;
            box-shadow: none !important;
        }
        &.invalid {
            color: #ff0059;
            input, textarea{
                border-color:#ff0059 !important;
            }
        }
    }
    .form_link {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        .sep {
            color: #a8a8a8;
        }
        a {
            color: #151515;
            font-weight: bold;
            font-size: 0.75rem;
            margin: 0 10px;
        }
    }
    .error-message {
        margin-bottom: 1rem;
        span {
            color: #ff0059;
            font-size: 12px;
            line-height: 1em;
            padding-bottom: 16px;
        }
    }
}

    .input_action_search {
        position: absolute;
        z-index: 14;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        img {
            transform: initial !important;
            left: initial !important;
            position: relative !important;
            top: initial !important;
        }
        .point {
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background: #bbbbce;
            display: block;
            &:not(:last-child) {
                margin-bottom: 3px;
            }
            
        }
    }
.input_row_fixed {

    position: relative;
    margin-top: 10px;
    width: 100%;
    background: #FFFFFF;
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 8px 10px;
    margin-bottom: 20px;
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        z-index: 14;
    }
}

.input_row {
    position: relative;
    margin-top: 10px;
    width: 100%;
    background: #FFFFFF;
    border: 3px solid #F2D358;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 8px 10px;
    margin-bottom: 20px;
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        z-index: 14;
    }
}

.input {
    appearance: none;
    width: 100%;
    background: #F0F0F5;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    height: 48px;
    padding-left: 52px;
    outline: none;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.button {
    width: 100%;
    appearance: none;
    border: 0;
    display: flex;
    border-radius: 8px;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
    font-weight: 600;
    outline: none;
    box-shadow: none;
    text-align: center;
    color: #181830;
    align-items: center;
    &.primary {
        background: $primary_color;
    }
    &.white {
        background: white;
        padding: 12px 24px;
        margin: 0 auto;
        width: auto;
        img {
            margin-left: 8px;
        }
    }
}

input, button {
    outline: none !important;
    box-shadow: none !important;
}

.box_green {
    background: #FFFFFF;
    border: 4px solid #004D33;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 10px 25px;
    margin-bottom: 85px;
    p{
        color: inherit !important;
    }
    input {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        background: #F0F0F5;
        border: 3px solid #004D33;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        padding: 10px;
        text-align: left;
    }
    button {
        background: #004D33;
        border-radius: 7px;
        width: auto;
        position: absolute;
        top: 0;
        margin: 0;
        padding: 0;
        bottom: 0;
        color: #fff;
        right: 10px;
        padding: 0 10px;
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        border-radius: 7px;
        line-height: 10px;
        border: 0;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}