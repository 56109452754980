.account_template {
    .wrapper {
        font-family: Inter;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
    }
    header {
        height: 165px;
        width: 100%;
        background: $primary_color;
        width: calc(100% + 48px);
        margin-left: -24px;
        position: relative;
        padding: 0 8px;
        &.no_search {
            height: 50px;
        }
        img.back {
            cursor:pointer;
            margin-right: 5px;
        }
        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 36px;
            color: #181830;
            font-weight: 700;
            position: relative;
            bottom: -10px;
            // left: 10px;
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            .logo {
                position: relative;
                bottom: 10px;
                max-width: 60%;
            }
            img {
                display: block;
            }
            
        }
        span.logout {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            text-align: right;

            /* Global / Charcoal Gray */

            color: #181830;
            opacity: 0.75;
            margin-right: 24px;
            cursor: pointer;
        }
        .search {
            background: #FFFFFF;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            width: 100%;
            height: 64px;
            margin: 8px 0;
        }
    }
}