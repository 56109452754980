.content {
    padding: 0 20px;

    width: calc(100% + 48px);
    margin: 0 -24px 25px -24px;
    background: $primary_color;
    &.med_padd {
        padding: 25px 40px;
        p {
            font-size: 14px;
            margin-top: 5px; 
        }
        .white {
            color: #FFFFFF;
        }
    }
    h4 {
        font-family: Inter;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */

        text-align: center;

        /* Global / Charcoal Gray */

        color: #181830;
        text-align: center;
        margin-top: 56px;
        font-weight: 700;
    }
    // margin-bottom: 88px;
    h2 {
        font-family: 'Inter';
        font-size: 22px;
        line-height: 36px;
        /* identical to box height, or 144% */
        padding:  0 13px;
        font-weight: bold;
        color: #000000;
        border-bottom: 2px solid;
        position: relative;
        img {
            margin-top: 12px;
            position: absolute;
            top: 24px;
            left: 11px;
        }
    }
    .wrapper {
        display: flex;
        padding: 30px 16px;
        background: #fff;
        border-radius: 0px 0px 10px 10px;
        flex-flow: column;
    }
    h3 {
        font-family: Inter;
        font-size: 28px;
        line-height: 32px;
        /* identical to box height, or 114% */

        display: flex;
        align-items: center;

        /* Global / True White */

        color: #FFFFFF;
        text-transform: uppercase;
        font-weight: 700
    }
    p {
        font-family: 'Inter';
        font-size: 15px;
        line-height: 24px;
        // 
        text-align: left;
        font-weight: 400;
        color: #000;
        // font-style: italic;
        &:not(:first-child) {
            margin-top: 12px;
        }
        strong {
            font-weight: 800;
            color: #181830;
            font-style: normal;
        }
    }
}