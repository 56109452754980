.search_container {
    padding-top: 30px;
    &.withPopup
    {
        padding-top: 74px;
        position: fixed;
        // background: $primary_color;
        // height: 100%;
        width: 100%;
        left: 0;
        // z-index: 9999999;
        .input_row_fixed {
            background: transparent;
        }
        .input_action_search {
            display: none;
        }
        .count {
            visibility: hidden;
        }
        .search_bar {
            background: transparent;
            box-shadow: none;
            height: 64px;
            &.animated {
                input {
                    // background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
                    // border: 0;
                    background: rgba(218, 218, 218, 0.47);
                    border: 3px solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 8px;
                }
                
            }
            input {
                background: transparent;
                border: 2px solid rgba(255,255,255,.4);
                color: #000000;
                &::-webkit-input-placeholder {
                    color: #000000;
                }
            }
        }
    }
    .search_bar {
        position: fixed;
        top: 0;
        background: #FFFFFF;
        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 16px 16px;
        height: 64px;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 8px;
        z-index: 9999;
        &.animated {
            transition: transform 450ms ease-in-out;
        }
        &.unSticked {
            transform: translateY(-100%)
        }
        &.isSticked {
            transform: translateY(0);
            background: #ffc34c;
            border-radius: 0;
            height: 74px;
        }
        &.account {
            position: relative;
            border-radius: 16px;
        }
        input {
            appearance: none;
            background: #F0F0F5;
            border-radius: 8px; 
            height: 48px;
            border: 0;
            // width: 300px;
            padding: 0 12px;
            outline: none;
            text-overflow: ellipsis;
            // padding-right: 50px;
            width: 100%;
        }
    }
    .count {
        font-family: Inter;
        font-size: 20px;
        line-height: 36px;
        color: #181830;
        font-weight: 800;
        position: relative;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        align-items: baseline;
        h5 {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            line-height: 16px;
            span {
                display: block;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                display: flex;
                align-items: center;

                color: #4CD964;
                
            }
        }
        img {
            position: absolute;
            top: 45px;
    left: 8px;
        }
    }
}

.funds_list {
    // background: #fff;
    // width: calc(100% + 48px);
    // margin-left: -24px;
    // padding: 104px 16px;
    background: #FFFFFF;
    border: 3px solid #F2D358;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: relative;
    top: -10px;
    z-index: 1;
    padding: 25px 10px;
}

.recent_search {
    background: #FFFFFF;
    border: 3px solid #F2D358;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: relative;
    top: -10px;
    z-index: 1;
    /* padding: 25px 10px; */
    // min-height: 440px;
    // &:before {
    //     content: '';
    //     background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #F0F0F5), to(rgba(240, 240, 245, 0)));
    //     background: -webkit-linear-gradient(top, #F0F0F5 50%, rgba(240, 240, 245, 0) 100%);
    //     background: linear-gradient(180deg, #F0F0F5 50%, rgba(240, 240, 245, 0) 100%);
    //     width: 100%;
    //     height: 360px;
    //     position: absolute;
    // }
    .wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        padding-left: 24px;
        padding-right: 24px;
        strong {
            font-family: Inter;
            font-size: 13px;
            line-height: 24px;
            /* identical to box height, or 185% */


            color: #A6A6A9;
            margin-bottom: 16px;
            display: block;
        }
        p {
            font-family: Inter;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */
            color: #A6A6A9;
            &:not(:first-child) {
                margin-bottom: 20px;
            }
            
            text-align: center;
        }
        h6 {
            font-family: Inter;
            font-size: 13px;
            line-height: 24px;
            color: #181830;
            font-weight: 800;
        }
        .recent-search-item {
            padding: 12px 0;
            border-bottom: 1px solid #E2E2E9;
            h4 {
                font-family: Inter;
                font-size: 16px;
                line-height: 24px;
                color: #181830;
                font-weight: 800;
            }
            span {
                text-transform: uppercase;
                color: #A6A6A9;
                margin-top: 7px;
                display: block
            }
        }
    }
}

.filters_search {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 9999999;
    overflow: auto;
    padding-bottom: 64px;
    .checkbox_filter_s {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #FFFFFF;
        border: 3px solid #A6A6A9;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px 16px;
        cursor: pointer;
        position: relative;
        span {
            width: 24px;
            height: 24px;
            border: 3px solid #F0F0F5;
            position: absolute;
            border-radius: 50%;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                display: none;
            }
        }
        small {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */


            /* Global / Charcoal Gray */

            color: #181830;
            opacity: 0.6;
            display: block;
            margin-top: 4px;
        }
        &.active {
            background: #049F50;
            color: #fff;
            border: 3px solid #CCCCCC;
            small {
                color: #fff;
            }
            span {
                background: #fff;
                
                img {
                    display: block;
                }
            }
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        
    }
    .wrapper {
        padding: 34px 16px;
        em {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            display: block;
            .point {
                width: 12px;
                height: 12px;
               
                display: inline-block;
                border-radius: 50%;
                &.red {
                    background: #E63E11;
                }
                &.green {
                    background: #049F50;
                }
            }
        }
        &.hasSep {
            padding: 34px 30px 0;
            &:after {
                content: '';
                height: 1px;
                width: 100%;
                background: #E2E2E9;
                display: block;
                position: relative;
                top: 10px;
            } 
        }
        h6 {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    
    h2 {
        background: #F0F0F5;
        padding: 23px 16px 10px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        position: relative;
        img {
            position: absolute;
            bottom: -8px;
            left: 17px;
        }
    }
    .line_switcher {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }
    .button_action {
        position: fixed;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
        border-radius: 16px 16px 0px 0px;
        bottom: 0;
        height: 64px;
        padding: 8px;
        display: flex;
        z-index: 999;
        max-width: $desktopWidth;
    }
}

.connectedUser {
    .filters_search {
        // height: calc(100% - 56px );
    }
    
}