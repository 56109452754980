@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    src: url("./Inter-Thin-BETA.woff2") format("woff2"),
         url("./Inter-Thin-BETA.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 100;
    src: url("./Inter-ThinItalic-BETA.woff2") format("woff2"),
         url("./Inter-ThinItalic-BETA.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    src: url("./Inter-ExtraLight-BETA.woff2") format("woff2"),
         url("./Inter-ExtraLight-BETA.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 200;
    src: url("./Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
         url("./Inter-ExtraLightItalic-BETA.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    src: url("./Inter-Light-BETA.woff2") format("woff2"),
         url("./Inter-Light-BETA.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 300;
    src: url("./Inter-LightItalic-BETA.woff2") format("woff2"),
         url("./Inter-LightItalic-BETA.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    src: url("./Inter-Regular.woff2") format("woff2"),
         url("./Inter-Regular.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    src: url("./Inter-Italic.woff2") format("woff2"),
         url("./Inter-Italic.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    src: url("./Inter-Medium.woff2") format("woff2"),
         url("./Inter-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 500;
    src: url("./Inter-MediumItalic.woff2") format("woff2"),
         url("./Inter-MediumItalic.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    src: url("./Inter-SemiBold.woff2") format("woff2"),
         url("./Inter-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 600;
    src: url("./Inter-SemiBoldItalic.woff2") format("woff2"),
         url("./Inter-SemiBoldItalic.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    src: url("./Inter-Bold.woff2") format("woff2"),
         url("./Inter-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 700;
    src: url("./Inter-BoldItalic.woff2") format("woff2"),
         url("./Inter-BoldItalic.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    src: url("./Inter-ExtraBold.woff2") format("woff2"),
         url("./Inter-ExtraBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 800;
    src: url("./Inter-ExtraBoldItalic.woff2") format("woff2"),
         url("./Inter-ExtraBoldItalic.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    src: url("./Inter-Black.woff2") format("woff2"),
         url("./Inter-Black.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 900;
    src: url("./Inter-BlackItalic.woff2") format("woff2"),
         url("./Inter-BlackItalic.woff") format("woff");
  }
  
  /* -------------------------------------------------------
  Variable font.
  Usage:
  
    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      html { font-family: 'Inter var', sans-serif; }
    }
  */
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("./Inter-upright.var.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("./Inter-italic.var.woff2") format("woff2");
  }
  
  
  /* --------------------------------------------------------------------------
  [EXPERIMENTAL] Multi-axis, single variable font.
  
  Slant axis is not yet widely supported (as of February 2019) and thus this
  multi-axis single variable font is opt-in rather than the default.
  
  When using this, you will probably need to set font-variation-settings
  explicitly, e.g.
  
    * { font-variation-settings: "slnt" 0deg }
    .italic { font-variation-settings: "slnt" 10deg }
  
  */
  @font-face {
    font-family: 'Inter var experimental';
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
    src: url("./Inter.var.woff2") format("woff2");
  }