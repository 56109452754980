.header {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    align-items: center;
    flex-flow: column;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #ffc34c;
    left: 0;
    top: 0;
    padding-bottom: 0;
    // &.sticky {
    //     .logo {
    //         img {
    //             width: 30px;
    //         }
    //         h1 {
    //             font-size: 20px;
    //         }
    //     }
    // }
    hr {
        border: 2px solid #004d33;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .login_link {
        width: 40px;
        height: 40px;
        background: $secondary_color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h2 {
        font-family: Inter;
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #004D33;
        margin-top: 5px;
    }
    .logo {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: center;
        img {
            width: 60px;
            transition: all 350ms ease-in-out;
        }
        h1 {
            text-transform: uppercase;
            color: $secondary_color;
            margin-left: 15px;
            font-size: 40px;
            font-weight: bold;
            span {
                display: block;
                text-transform: initial;
                font-size: 11px;
                font-weight: normal;
                margin-top: 3px;
            }
        }
    }
}