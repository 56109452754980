.popup {
  position: fixed;
  top: 0;
  // left: 1%;
  width: 100%;
  height: calc(100% - 37px);
  z-index: 99999999;
  transition: all 450ms ease-in-out;
  transform: translate3d(0, 150%, 0px);
  background: $primary_color;
  padding: 0 14px;
  &.reveal {
    transform: translate3d(0, 0, 0px);
    top: 74px;
  }
  header {
    height: 62px;
    padding: 5px 24px;
    .logo {
      display: flex;
      align-items: center;
      h1 {
        text-transform: uppercase;
        color: $secondary_color;
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .follow_button {
    position: fixed;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 16px 16px 0px 0px;
    bottom: 0;
    height: 64px;
    padding: 8px;
    display: flex;
    z-index: 999;
    display: none;
  }
  .close {
    position: absolute;
    right: 22px;
    top: -51px;
    opacity: 1;
    z-index: 14;
  }
  .content-popup {
    background: #ffffff;
    border: 4px solid #004d33;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    -webkit-overflow-scrolling: touch;
    padding: 32px 5px 0;
    overflow: scroll;
    overflow-x: hidden;
    height: inherit;
    position: relative;
    .last_years {
      display: flex;
      justify-content: space-around;
      font-family: Inter;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #99999c;
      font-weight: 800;
      margin-top: 20px;
      div {
        padding: 0 15px;
        &:not(:first-child) {
          box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
        }
      }
      span {
        display: block;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        margin-top: 8px;
        text-align: center;
        color: #99999c;
        &.positive {
          color: #049f50;
        }
        &.negative {
          color: #e63e11;
        }
      }
    }
    h1 {
      font-family: "Inter";
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 16px;
      padding-left: 15px;
      // max-width: 60%;
    }
    em {
      margin-top: 32px;
      font-family: "Inter";
      font-size: 13px;
      line-height: 20px;
      /* identical to box height, or 154% */

      text-align: right;

      /* Global / Charcoal Gray */

      color: #181830;
      font-style: italic;
      font-weight: 500;
      text-align: center;
      display: block;
      display: flex;
      justify-content: center;
    }
    .box-light-gray {
      background: #f0f0f5;
      border-radius: 8px;
      width: 100%;
      padding: 24px;
      margin-top: 40px;
      .numbers {
        display: flex;
        justify-content: space-between;
        margin-top: 74px;
        width: calc(100% + 28px);
        margin-left: -14px;
        position: relative;
        .level_down {
          position: absolute;
          top: -50px;
          left: 0;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          color: #99999c;
          padding-left: 13px;
        }
        .level_up {
          position: absolute;
          bottom: -50px;
          right: 0;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          color: #99999c;
          text-align: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-right: 15px;
        }
        span {
          display: flex;
          width: 40px;
          height: 40px;
          background: #fff;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 4px;
          font-family: Inter;
          font-size: 20px;
          line-height: 40px;
          font-weight: 800;
          color: #094b7a;
          &.active {
            background: #094b7a;
            color: #ffffff;
            transform: scale(1.35);
          }
        }
      }
      h5 {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        color: #094b7a;
        margin-top: 4px;
        text-align: center;
      }
      h4 {
        font-family: "Inter";
        font-size: 20px;
        line-height: 28px;
        color: #181830;
        font-weight: 800;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        div {
          span {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 8px;
            display: inline-block;
          }
          text-transform: uppercase;
          font-family: Inter;
          font-size: 12px;
          line-height: 28px;
          display: flex;
          align-items: center;
        }
      }
    }
    .box-white {
      background: #fff;
      border: 3px solid #f0f0f5;
      border-radius: 8px;
      width: 100%;
      padding: 24px;
      margin-top: 40px;
      h4 {
        font-family: "Inter";
        font-size: 20px;
        line-height: 28px;
        color: #181830;
        font-weight: 800;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      h5 {
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        font-weight: 800;
        margin-top: 24px;
      }
      .content_minimized {
        p {
          font-family: Inter;
          font-size: 16px;
          line-height: 24px;
          margin-top: 16px;
          color: #404043;
        }
        position: relative;
        max-height: 135px;
        overflow: hidden;
        &.full {
          max-height: initial;
          overflow: visible;
        }
        span {
          position: absolute;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            88.4deg,
            rgba(255, 255, 255, 0) 0.4%,
            #ffffff 45.22%
          );
          width: 83px;
          font-family: Inter;
          font-size: 13px;
          line-height: 20px;
          /* identical to box height, or 154% */

          text-align: right;

          /* Canary / Primary */

          color: #ffc34c;
        }
      }
    }
    .box-blue {
      background: #44a0d9;
      border-radius: 8px;
      width: 100%;
      padding: 24px 10px;
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      p {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        strong {
          color: #181830;
        }
        @include iphone5 {
          font-size: 14px;
        }
      }
      .round {
        width: 60px;
        height: 60px;
        border: 2px solid #fff;
        border-radius: 50%;
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
          margin-top: -5px;
        }
        @include iphone5 {
          width: 50px;
          height: 50px;
        }
      }
    }
    .point {
      span {
        width: 30px;
        height: 30px;
        background: #85bb2f;
        border-radius: 50%;
        margin-right: 12px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 64px;
      font-family: "Inter";
      font-size: 28px;
      line-height: 36px;
      color: #181830;
      font-weight: 700;
      text-transform: uppercase;
    }
    .code {
      display: flex;
      justify-content: space-between;
      // padding: 0 15px;
      .isin {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #a6a6a9;
        padding: 0 15px;
      }
      .category {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        letter-spacing: -0.02em;
        color: #a6a6a9;
        padding: 0 15px;
        text-align: right;
      }
    }
  }
}

.fund_details {
  position: relative;
  .share_button {
    bottom: 10px;
    right: 10px;
  }
  .follow_button {
    position: fixed;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 16px 16px 0px 0px;
    bottom: 0;
    height: 64px;
    padding: 8px;
    display: flex;
    z-index: 999;
    display: none;
  }
  .close {
    position: absolute;
    right: 12px;
    top: -51px;
    opacity: 1;
    z-index: 14;
  }
  .content-popup {
    background: #ffffff;
    border: 4px solid #004d33;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    -webkit-overflow-scrolling: touch;
    padding: 32px 5px 32px;
    overflow: auto;
    overflow-x: hidden;
    height: inherit;
    position: relative;
    .last_years {
      display: flex;
      justify-content: space-around;
      font-family: Inter;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #99999c;
      font-weight: 800;
      margin-top: 20px;
      div {
        padding: 0 15px;
        &:not(:first-child) {
          box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
        }
      }
      span {
        display: block;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        margin-top: 8px;
        text-align: center;
        color: #99999c;
        &.positive {
          color: #049f50;
        }
        &.negative {
          color: #e63e11;
        }
      }
    }
    h1 {
      font-family: "Inter";
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 16px;
      padding-left: 15px;
      // max-width: 60%;
    }
    em {
      margin-top: 32px;
      font-family: "Inter";
      font-size: 13px;
      line-height: 20px;
      /* identical to box height, or 154% */

      text-align: right;

      /* Global / Charcoal Gray */

      color: #181830;
      font-style: italic;
      font-weight: 500;
      text-align: center;
      display: block;
      display: flex;
      justify-content: center;
    }
    .box-light-gray {
      background: #f0f0f5;
      border-radius: 8px;
      width: 100%;
      padding: 24px;
      margin-top: 40px;
      .numbers {
        display: flex;
        justify-content: space-between;
        margin-top: 74px;
        width: calc(100% + 28px);
        margin-left: -14px;
        position: relative;
        .level_down {
          position: absolute;
          top: -50px;
          left: 0;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          color: #99999c;
          padding-left: 13px;
        }
        .level_up {
          position: absolute;
          bottom: -50px;
          right: 0;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          color: #99999c;
          text-align: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-right: 15px;
        }
        span {
          display: flex;
          width: 40px;
          height: 40px;
          background: #fff;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 4px;
          font-family: Inter;
          font-size: 20px;
          line-height: 40px;
          font-weight: 800;
          color: #094b7a;
          &.active {
            background: #094b7a;
            color: #ffffff;
            transform: scale(1.35);
          }
        }
      }
      h5 {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        color: #094b7a;
        margin-top: 4px;
        text-align: center;
      }
      h4 {
        font-family: "Inter";
        font-size: 20px;
        line-height: 28px;
        color: #181830;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        div {
          span {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 8px;
            display: inline-block;
          }
          text-transform: uppercase;
          font-family: Inter;
          font-size: 12px;
          line-height: 28px;
          display: flex;
          align-items: center;
        }
      }
    }
    .box-white {
      background: #fff;
      border: 3px solid #f0f0f5;
      border-radius: 8px;
      width: 100%;
      padding: 24px;
      margin-top: 40px;
      h4 {
        font-family: "Inter";
        font-size: 20px;
        line-height: 28px;
        color: #181830;
        font-weight: 800;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      h5 {
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        font-weight: 800;
        margin-top: 24px;
      }
      .content_minimized {
        p {
          font-family: Inter;
          font-size: 16px;
          line-height: 24px;
          margin-top: 16px;
          color: #404043;
        }
        position: relative;
        max-height: 135px;
        overflow: hidden;
        &.full {
          max-height: initial;
          overflow: visible;
        }
        span {
          position: absolute;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            88.4deg,
            rgba(255, 255, 255, 0) 0.4%,
            #ffffff 45.22%
          );
          width: 83px;
          font-family: Inter;
          font-size: 13px;
          line-height: 20px;
          /* identical to box height, or 154% */

          text-align: right;

          /* Canary / Primary */

          color: #ffc34c;
        }
      }
    }
    .box-blue {
      background: #44a0d9;
      border-radius: 8px;
      width: 100%;
      padding: 24px 10px;
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      p {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        strong {
          color: #181830;
        }
      }
      .round {
        width: 60px;
        height: 60px;
        border: 2px solid #fff;
        border-radius: 50%;
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
          margin-top: -5px;
        }
      }
    }
    .point {
      span {
        width: 30px;
        height: 30px;
        background: #85bb2f;
        border-radius: 50%;
        margin-right: 12px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 64px;
      font-family: "Inter";
      font-size: 28px;
      line-height: 36px;
      color: #181830;
      font-weight: 700;
      text-transform: uppercase;
    }
    .code {
      display: flex;
      justify-content: space-between;
      // padding: 0 15px;
      .isin {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #a6a6a9;
        padding: 0 15px;
      }
      .category {
        font-family: "Inter";
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        letter-spacing: -0.02em;
        color: #a6a6a9;
        padding: 0 15px;
        text-align: right;
      }
    }
  }
}

// .{
//     position: relative;
//     top: 0;
//     left: 0;
//     -webkit-transition: all 450ms ease-in-out;
//     transition: all 450ms ease-in-out;
//     width: 100%;
//     width: 100%;
//     background: #FFFFFF;
//     border: 4px solid #004D33;
//     box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
//     border-radius: 16px;
//     padding-bottom: 56px;
//     background: #fff;
//     width: calc(100% + 24px);
//     margin-left: -12px;
//     header {
//         height: 385px;
//         position: absolute;
//         z-index: 14;
//         // top: -57px;
//         left: 0;
//         width: 100%;
//         &.very_bad {
//             background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.52%, #FFFFFF 100%), rgba(230, 62, 17, 0.25);
//             .logo {
//                 h2 {
//                     color: #E63E11;
//                 }
//             }
//             .login_link {
//                 background: #E63E11;
//             }
//         }
//         &.bad {
//             background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.52%, #FFFFFF 100%), rgba(238, 129, 0, 0.25);
//             .logo {
//                 h2 {
//                     color: #EE8100;
//                 }
//             }
//             .login_link {
//                 background: #EE8100;
//             }
//         }
//         &.medium {
//             background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.52%, #FFFFFF 100%), rgba(254, 203, 2, 0.25);
//             .logo {
//                 h2 {
//                     color: #FECB02;
//                 }
//             }
//             .login_link {
//                 background: #FECB02;
//             }
//         }
//         &.good {
//             background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.52%, #FFFFFF 100%), rgba(133, 187, 47, 0.25);
//             .logo {
//                 h2 {
//                     color: #85BB2F;
//                 }
//             }
//             .login_link {
//                 background: #85BB2F;
//             }
//         }
//         &.very_good {
//             background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.52%, #FFFFFF 100%), rgba(4, 159, 80, 0.25);
//             .logo {
//                 h2 {
//                     color: #049F50;
//                 }
//             }
//             .login_link {
//                 background: #049F50;
//             }
//         }
//         span {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             padding: 5px 24px;
//             .logo {
//                 display: flex;
//                 align-items: center;
//                 h2 {
//                     text-transform: uppercase;
//                     color: #004d33;
//                     margin-left: 15px;
//                     font-size: 22px;
//                     font-weight: 700;
//                 }

//             }
//             .login_link {
//                 width: 40px;
//                 height: 40px;
//                 background: #004d33;
//                 border-radius: 50%;
//                 justify-content: center;
//                 display: flex;
//                 align-items: center;
//             }
//         }
//     }
//     .follow_button {
//         position: fixed;
//         width: 100%;
//         background: #FFFFFF;
//         box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
//         border-radius: 16px 16px 0px 0px;
//         bottom: 0;
//         height: 64px;
//         padding: 8px;
//         display: flex;
//         z-index: 999;
//     }
//     .close {
//         position: absolute;
//         right: 16px;
//         top: 37px;
//         opacity: 1;
//         z-index: 14;
//     }
//     .content-popup {
//         // background: #fff;
//         padding: 32px 24px;
//         // height: calc(100% - 385px);
//         overflow: auto;
//         padding-bottom: 64px;
//         top: 60px;
//         position: relative;
//         z-index: 20;
//         -webkit-overflow-scrolling: touch ;
//         .last_years {
//             display: flex;
//             justify-content: space-around;
//             font-family: Inter;
//             font-size: 14px;
//             line-height: 20px;
//             text-align: center;
//             letter-spacing: -0.02em;
//             color: #99999C;
//             font-weight: 800;
//             margin-top: 20px;
//             div {
//                 padding:  0 15px;
//                 &:not(:first-child) {
//                     box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
//                 }

//             }
//             span {
//                 display: block;
//                 font-family: Inter;
//                 font-size: 16px;
//                 line-height: 24px;
//                 /* identical to box height, or 150% */
//                 margin-top: 8px;
//                 text-align: center;
//                 color: #049F50;
//                 &.negative {
//                     color: #E63E11
//                 }
//             }
//         }
//         h1 {
//             font-family: 'Inter';
//             font-size: 28px;
//             line-height: 36px;
//             font-weight: 700;
//             margin-bottom: 16px;
//             padding-left: 15px;
//             // max-width: 60%;
//         }
//         em {
//             margin-top: 32px;
//             font-family: 'Inter';
//             font-size: 13px;
//             line-height: 20px;
//             /* identical to box height, or 154% */

//             text-align: right;

//             /* Global / Charcoal Gray */

//             color: #181830;
//             font-style: italic;
//             font-weight: 500;
//             text-align: center;
//             // display: block;
//             display: flex;
//             justify-content: center;
//         }
//         .box-light-gray {
//             background: #F0F0F5;
//             border-radius: 8px;
//             width: 100%;
//             padding: 24px;
//             margin-top: 40px;
//             .numbers {
//                 display: flex;
//                 justify-content: space-between;
//                 margin-top: 74px;
//                 width: calc(100% + 28px);
//                 margin-left: -14px;
//                 position: relative;
//                 .level_down {
//                     position: absolute;
//                     top: -50px;
//                     left: 0;
//                     font-family: Inter;
//                     font-size: 12px;
//                     line-height: 16px;
//                     color: #99999C;
//                     padding-left: 13px;
//                 }
//                 .level_up {
//                     position: absolute;
//                     bottom: -50px;
//                     right: 0;
//                     font-family: Inter;
//                     font-size: 12px;
//                     line-height: 16px;
//                     color: #99999C;
//                     text-align: right;
//                     display: flex;
//                     flex-direction: column;
//                     align-items: flex-end;
//                     padding-right: 15px;
//                 }
//                 span {
//                     display: flex;
//                     width: 40px;
//                     height: 40px;
//                     background: #fff;
//                     justify-content: center;
//                     align-items: center;
//                     border-radius: 50%;
//                     margin-right: 4px;
//                     font-family: Inter;
//                     font-size: 20px;
//                     line-height: 40px;
//                     font-weight: 800;
//                     color: #094B7A;
//                     &.active {
//                         background: #094B7A;
//                         color: #ffffff;
//                         transform: scale(1.35);
//                     }
//                 }
//             }
//             h5 {
//                 font-family: 'Inter';
//                 font-size: 14px;
//                 line-height: 20px;
//                 color: #094B7A;
//                 margin-top: 4px;
//             }
//             h4 {
//                 font-family: 'Inter';
//                 font-size: 20px;
//                 line-height: 28px;
//                 color: #181830;
//                 font-weight: 800;
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 div {
//                     span {
//                         width: 20px;
//                         height: 20px;
//                         border-radius: 50%;
//                         margin-right: 8px;
//                         display: inline-block;
//                     }
//                     text-transform: uppercase;
//                     font-family: Inter;
//                     font-size: 12px;
//                     line-height: 28px;
//                     display: flex;
//                     align-items: center;
//                 }
//             }
//         }
//         .box-white {
//             background: #fff;
//             border: 3px solid #F0F0F5;
//             border-radius: 8px;
//             width: 100%;
//             padding: 24px;
//             margin-top: 40px;
//             h4 {
//                 font-family: 'Inter';
//                 font-size: 20px;
//                 line-height: 28px;
//                 color: #181830;
//                 font-weight: 800;
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//             }
//             h5 {
//                 font-family: Inter;
//                 font-size: 16px;
//                 line-height: 24px;
//                 color: #000000;
//                 font-weight: 800;
//                 margin-top: 24px;
//             }
//             .content_minimized {
//                 p{
//                     font-family: Inter;
//                     font-size: 16px;
//                     line-height: 24px;
//                     margin-top: 16px;
//                     color: #404043;
//                 }
//                 position: relative;
//                 max-height: 135px;
//                 overflow: hidden;
//                 &.full {
//                     max-height: initial;
//                     overflow: visible;
//                 }
//                 span {
//                     position: absolute;
//                     right: 0;
//                     bottom: 0;
//                     background: linear-gradient(88.4deg, rgba(255, 255, 255, 0) 0.4%, #FFFFFF 45.22%);
//                     width: 83px;
//                     font-family: Inter;
//                     font-size: 13px;
//                     line-height: 20px;
//                     /* identical to box height, or 154% */

//                     text-align: right;

//                     /* Canary / Primary */

//                     color: #FFC34C;
//                 }
//             }
//         }
//         .box-blue {
//             background: #44A0D9;
//             border-radius: 8px;
//             width: 100%;
//             padding: 24px 10px;
//             margin-top: 40px;
//             display: flex;
//             justify-content: space-around;
//             align-items: center;
//             p{
//                 color: #fff;
//                 font-family: Inter;
//                 font-size: 18px;
//                 line-height: 26px;
//                 font-weight: 700;
//                 strong {
//                     color: #181830;
//                 }

//             }
//             .round {
//                 width: 60px;
//                 height: 60px;
//                 border: 2px solid #fff;
//                 border-radius: 50%;
//                 img {
//                     object-fit: contain;
//                     height: 100%;
//                     width: 100%;
//                     margin-top: -5px;
//                 }
//             }
//         }
//         .point {
//             span {
//                 width: 30px;
//                 height: 30px;
//                 background: #85BB2F;
//                 border-radius: 50%;
//                 margin-right: 12px;
//             }
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             margin-top: 64px;
//             font-family: 'Inter';
//             font-size: 28px;
//             line-height: 36px;
//             color: #181830;
//             font-weight: 700;
//             text-transform: uppercase
//         }
//         .code {
//             display: flex;
//             justify-content: space-between;
//             padding: 0 15px;
//             .isin {
//                 font-family: 'Inter';
//                 font-size: 14px;
//                 line-height: 20px;
//                 /* identical to box height, or 143% */
//                 font-weight: 500;
//                 font-size: 14px;
//                 line-height: 20px;
//                 color: #A6A6A9;
//             }
//             .category {
//                 font-family: 'Inter';
//                 font-size: 14px;
//                 line-height: 20px;
//                 /* identical to box height, or 143% */
//                 font-weight: 500;
//                 font-size: 14px;
//                 line-height: 20px;
//                 color: #A6A6A9;

//             }
//         }
//     }
// }
