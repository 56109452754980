.slider-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
  align-items: center;
  max-width: 80%;
  margin: 20px auto;
  span {
    width: calc(20% - 1px);
    height: 8px;
    background: #e63e11;
    display: flex;
    justify-content: space-between;
    &.active {
      height: 16px;
      border-radius: 4px;
      em {
        margin-top: 24px !important;
      }
    }
    em {
      margin-top: 20px !important;
      display: block !important;
      font-family: Inter;
      font-size: 10px !important;
      line-height: 16px !important;
      margin-left: -5px;
    }

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.slide_container {
  height: 70px;
  display: block;
  display: flex;
  align-items: flex-end;
  max-width: 80%;
  margin: 0 auto;
}

.rounded_tooltip {
  position: absolute;
  z-index: 99999;
  color: #fff;
  font-family: "Inter";
  border-color: transparent;
  height: 68px;
  width: 68px;
  font-size: 28px;
  font-weight: 700;
  margin-left: -34px;
  margin-top: -53px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 250ms linear;
  transform: translateY(-30px);
  &.zoom {
    opacity: 1;
    transform: translateY(0);
  }
  // &:after {
  //     content: '';
  //     position: absolute;
  //     width: 0;
  //     height: 0;
  //     border-style: solid;
  //     border-width: 16px 10px 0 10px;
  //     border-color: #181830 transparent transparent transparent;
  //     bottom: 0;
  //     left: 50%;
  //     bottom: -7px;
  //     left: 50%;
  //     transform: translateX(-50%);
  // }
}

.rc-slider-handle {
  transition: all 250ms linear;
  opacity: 0;
  transform: translateY(-30px);
  &.zoom {
    opacity: 1;
    transform: translateY(0);
  }
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-style: solid;
    /* border-width: 16px 10px 0 10px; */
    bottom: 0;
    left: 50%;
    bottom: -5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%) rotate(-45deg);
    color: inherit;
    background: inherit;
  }
  &:before {
    content: "Points Canary";
    z-index: 999;
    position: absolute;
    color: #fff;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    bottom: 0px;
    text-align: left;
    left: 4px;
  }
  // &:after {
  //     content: '';
  //     position: absolute;
  //     width: 24px;
  //     height: 24px;
  //     // background: #181830;
  //     top: 50%;
  //     transform: translateY(-50%) translateX(-50%);
  //     margin-left: 48px;
  //     left: 50%;
  // }
}
