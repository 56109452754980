body {
  background-color: $primary_color;
}

// #root {
//     background-color: $primary_color;
// }
.clickable {
  cursor: pointer;
}
.back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header_backdrop {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    color: #fff;
    align-items: center;
    img {
      max-width: 32px;
    }
    h2 {
      font-family: "Inter";
      text-transform: uppercase;
      margin-left: 8px;
      font-weight: 800;
      font-size: 22px;
    }
  }
}

.account_content {
  // background: #fff;
  // width: calc(100% + 48px);
  // margin-left: -24px;
  padding: 34px 0;
  .wrap {
    padding: 0 24px;
  }
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiTab-root {
  padding: 0 !important;
  max-width: initial !important;
}

.info_form {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: 800;
  font-style: italic;
}

.App {
  width: 100%;
  margin: 0 auto;
  padding: 0 14px;
  position: relative;
  overflow: hidden;
  padding-top: 250px;
  background: $primary_color;
  &.isDesktop {
    max-width: $desktopWidth;
  }
  &.connectedUser {
    // padding-bottom: 56px;
  }
}

.bottom_menu {
  background: #181830;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 99999;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    // padding-top: 15px;
    /* or 143% */
    display: flex;
    align-items: center;
    text-align: center;
    /* Global / True White */
    color: #ffffff;
    opacity: 0.6;
    position: relative;
    text-decoration: none;
    &:hover {
      color: #fff !important;
    }
    &.active {
      opacity: 1;
      &:before {
        content: "";
        position: absolute;
        border-top: 2px solid rgba(255, 255, 255, 0.75);
        width: 100%;
        top: -12px;
      }
    }
  }
}

a:hover {
  color: inherit;
  text-decoration: none;
}

footer {
  margin-top: 30px !important;
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    a {
      display: inline;
    }
  }
  a {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* or 150% */

    text-align: center;

    color: #004d33;
    display: block;
    // padding: 0 5%;
    &:hover {
      color: #000 !important;
    }
    span {
      display: inline-block;
    }
  }
  em {
    margin-top: 30px !important;
    font-family: Inter;
    font-size: 16px !important;
    line-height: 22px;
    font-style: normal !important;
    text-align: center;
    display: block;
    color: #004d33 !important;
    display: block !important;
    a {
      color: #004d33;
      font-size: 12px !important;
      line-height: 18px;
      margin-bottom: initial;
      display: inline;
      padding: 0;
    }
  }
}

.why_canary_text {
  p {
    font-family: Inter;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #181830 !important;
    margin-top: 0 !important;
    font-weight: 500 !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 24px;
    &:not(:first-child) {
      padding-top: 24px;
    }
  }
}

.box {
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 32px 24px 0;
  margin: 45px auto;
  // height: 420px;
  h2 {
    font-family: "Inter";
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
    font-weight: 800;
  }
  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* Global / Charcoal Gray */

    color: #181830;
    opacity: 0.6;
    margin-top: 16px;
  }
  footer {
    position: relative;
    width: calc(100% + 48px);
    height: 65px;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0)),
      to(#ffffff)
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    span {
      font-family: "Inter";
      font-size: 13px;
      line-height: 20px;
      text-align: right;
      font-style: italic;
      font-weight: 500;
    }
  }
}

.anchor {
  display: block;
  width: 100%;
  hr {
    border: 2px solid #004d33;
    width: 100%;
  }
  img {
    margin-right: 5px;
  }
  h5 {
    font-family: Inter;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    display: flex;
    justify-content: flex-end;
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 30px;
    margin-bottom: 60px;
    float: right;
  }
  h3 {
    font-family: Inter;
    font-style: italic;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-top: 60px;
    padding: 20px;
  }
  a {
    background: #004d33;
    border: 2px solid #f2d358;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 7px;
    margin: 0 auto;
    display: table;
    width: 250px;
    color: #fff;
    font-family: Inter;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    svg {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.box_nl {
  background: #181830;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 88px 32px;
  width: calc(100% - 48px);
  margin-top: 88px;
  position: absolute;
  z-index: 99;
  left: 24px;
  input {
    background: #ffffff;
    width: 100%;
    border: 3px solid #ffc34c;
    box-sizing: border-box;
    border-radius: 8px;
    height: 64px;
    appearance: none;
    margin-top: 24px;
    text-align: center;
    font-weight: 800;
  }
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: flex-end;

    /* Global / True White */

    color: #ffffff;
    margin-top: 0 !important;
    img {
      padding: 12px 0 32px;
    }
  }
  p {
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    font-weight: 700 !important;
    text-align: center;
    font-style: normal !important;
  }
}

.flex_pos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.bullet_point {
  div {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    position: relative;
    padding: 5px 0;
    .hilighted {
      position: absolute;
      width: calc(100% + 48px);
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: -24px;
      z-index: -1;
      // padding:  4px 0;
    }
    &.very_good {
      span {
        background: #049f50;
      }
    }
    &.good {
      span {
        background: #85bb2f;
      }
    }
    &.medium {
      span {
        background: rgb(212, 221, 92);
      }
    }
    &.bad {
      span {
        background: #ee8100;
      }
    }
    &.very_bad {
      span {
        background: #e63e11;
      }
    }
    span {
      width: 20px;
      height: 20px;
      left: 24px;
      top: 382px;

      /* Grades / Very Good */

      display: inline-flex;
      border-radius: 20px;
      margin-right: 8px;
    }
  }
}

/** Desktop CSS FIX **/
.popup {
  &.isDesktop {
    max-width: $desktopWidth !important;
    left: 50% !important;
    margin-left: -$desktopWidth / 2;
  }
}

.modal_info {
  &.isDesktop {
    max-width: $desktopWidth !important;
    left: 50% !important;
    .explanation_fees {
      max-width: 50%;
    }
  }
}

.modal_shadow {
  &.isDesktop {
    header {
      max-width: $desktopWidth !important;
      left: 50% !important;
      margin: 0 auto;
    }
  }
}

.isDesktop {
  // border-left: 2px solid darken($primary_color, 30);
  // border-right: 2px solid darken($primary_color, 30);
  .popup,
  .search_bar:not(.account),
  .modal_shadow,
  .filters_search,
  .withPopup,
  .header {
    max-width: $desktopWidth !important;
    left: 50% !important;
    margin-left: -$desktopWidth / 2;
  }
  .modal_info {
    max-width: $desktopWidth - 20px;
    left: 50% !important;
  }
  .bottom_menu {
    max-width: $desktopWidth - 4px;
    left: calc(50% + 2px) !important;
    margin-left: $desktopWidth / 2;
  }
}

.MuiTabs-indicator {
  background: #000 !important;
  z-index: 999;
}

.error-page {
  padding: 20px 0;
}

.pl-35 {
  padding-left: 35px !important;
}

.swipeable_header {
  // border-bottom: 2px solid #fff;
  width: calc(100% + 48px) !important;
  margin-left: -20px;
  padding: 0 24px;
  overflow: auto;
  position: relative !important;
  background: transparent !important;
  box-shadow: none !important;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    bottom: 0;
    left: 0;
  }
  .wrapper {
    // width: 1100px;
    display: flex;
    /* overflow: auto; */
    /* transform: translateX(9px); */
    padding: 10px 24px;
  }
  span {
    font-family: Inter;
    font-size: 14px;
    line-height: 24px;
    margin-right: 24px;
    /* identical to box height, or 171% */

    text-align: center;
    letter-spacing: -0.02em;

    /* Global / Charcoal Gray */

    color: #181830;
    text-transform: initial;
  }
}

.tooltip_canary {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  position: relative;
  &.ttip {
    &.show {
      &:after {
        opacity: 1;
        transform: translateX(-50%) translate3d(0, 0, 0);
      }
    }
    &:after {
      transition: all 0.3s ease-in-out;
      opacity: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #000000;
      border-left: 6px solid transparent;
      content: "";
      height: 0;
      top: 24px;
      left: 50%;
      width: 0;
      position: absolute;
      transform: translateX(-50%) translate3d(0, 6px, 0);
    }
  }

  svg {
    max-width: 80%;
  }
  &.right {
    .content_tooltip {
      right: -10px;
      left: inherit;
      &:after {
        bottom: -6px;
        right: 12px;
        left: inherit;
      }
    }
  }
  .content_tooltip {
    position: absolute;
    width: 300px;
    background: #000000;
    left: -100px;
    top: 30px;
    z-index: 999999;
    padding: 6px 15px;
    text-align: left;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s 0.1s ease-in-out;
    opacity: 0;
    transform: scale3d(0.2, 0.2, 1);
    &.show {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
}

.account_line {
  border-bottom: 1px solid #e2e2e9;
  padding: 16px;
  &.edit_mode {
    background: #f0f0f5;
  }
  .edit_content {
    margin-top: 20px;
    input {
      background: #ffffff;
      border-radius: 8px;
      appearance: none;
      width: 100%;
      height: 48px;
      border: 0;
      padding: 16px;
      margin-bottom: 8px;
    }
  }
  h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #404043;
    font-weight: 500;
  }
  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Global / Charcoal Gray */

    color: #181830;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    margin-bottom: 6px;
    span {
      display: flex;
      align-items: center;
      img {
        display: inline-flex;
        left: 24px;
        top: 297px;

        /* Global / True White */

        background: #fff;
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    button {
      width: 88px;
      height: 36px;
      left: 263px;
      top: 198px;

      /* Global / Clear Gray */

      background: #f0f0f5;
      border-radius: 8px;
      appearance: none;
      border: 0;
      &:disabled {
        opacity: 0.2;
      }
      &.editing {
        background: #181830;
        color: #fff;
      }
    }
  }
}

.page {
  padding: 20px 0;
  .wrapper {
    display: flex;
    padding: 30px 16px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    flex-flow: column;
    h3 {
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 20px;
      font-size: 18px;
    }
    p {
      margin-bottom: 20px;
      strong {
        font-weight: bold;
        display: block;
      }
    }
  }
  h1 {
    font-family: "Inter";
    font-size: 25px;
    line-height: 36px;
    padding: 0 13px;
    font-weight: bold;
    color: #000000;
    border-bottom: 2px solid;
    position: relative;
    img {
      margin-top: 12px;
      position: absolute;
      bottom: -16px;
      left: 11px;
    }
  }
}

.clear {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: #a6a6a9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  img {
    position: relative !important;
    top: initial !important;
    transform: none !important;
    left: initial !important;
    z-index: 14;
  }
}

.searchPage {
  padding-top: 0;
  .header {
    padding-bottom: 0;
    .input_row {
      margin-bottom: 0;
    }
    hr {
      display: none;
    }
  }
  .search_wrapper {
    position: relative;
    padding-top: 195px;
    .input_row {
      display: none;
    }
  }
}

.share_button {
  appearance: none;
  background: transparent;
  border: 0;
  text-align: center;
  display: table;
  margin: 19px auto;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #181830;
  span {
    width: 22px;
    height: 22px;
    display: inline-block;
    border-radius: 50%;
    background: rgba(24, 24, 48, 0.15);
    margin-left: 5px;
    vertical-align: middle;
    svg {
      path {
        fill: #181830;
      }

      width: 13px;
    }
  }
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
  text-align: center;
}

.Toastify__toast--success {
  background: #004d33 !important;
}
.slider {
  padding-top: 30px;
  h4 {
    font-family: "Inter";
    font-size: 20px;
    line-height: 28px;
    color: #181830;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 24px;
  }
}

.euro-display {
  font-family: "Zilla Slab", serif;
  font-size: 25px;
  margin-top: 6px;
  // display: flex;
  // align-items: center;
  div {
    // display: flex;
    // align-items: center;
  }

  span {
    margin: 0 6px 0 0;
    color: #4d181830;
    display: inline-block;
    &:first-child {
      font-size: 21px;
    }
    &:nth-child(2) {
      font-size: 28px;
    }
    &:nth-child(3) {
      font-size: 35px;
    }
    &:nth-child(4) {
      font-size: 43px;
    }
    &.active {
      color: #181830;
    }
  }
}
