.canary_card {
  width: 100%;
  background: #ffffff;
  border: 3px solid #f0f0f5;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  min-height: 90px;
  display: flex;
  align-items: center;
  .chevron,
  .chevron-card {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
  .bande {
    position: absolute;
    width: 40px;
    height: calc(100% + 6px);
    background: red;
    right: 32px;
    top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    em {
      width: 32px;
      height: 32px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Inter;
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      /* or 150% */

      display: flex;
      align-items: center;
      text-align: center;

      /* Global / Charcoal Gray */

      color: #181830;
      font-weight: 800;
    }
  }
  .point_card {
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    span {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  h2 {
    font-family: "Inter";
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding-right: 15px;
    max-width: calc(100% - 55px);
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.swiper-container {
  padding-bottom: 40px !important;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  .swiper-pagination-bullet-active {
    background: #181830 !important;
  }
}
