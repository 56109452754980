.modal_shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #181830;
  opacity: 0.9;
  opacity: 0;
  z-index: -1;
  &.show {
    opacity: 0.97;
    z-index: 99999999;
  }
}

.modal_info {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: 95%;
  border-radius: 16px;
  transform: translateX(-50%);
  transition: all 350ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  max-height: calc(100vh - 134px);
  @media screen and (device-aspect-ratio: 40/71) {
    max-height: 400px;
  }
  &.show {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
    z-index: 9999999999;
    visibility: visible;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  header {
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #181830;
      color: #fff;
    }
  }
  #content_modal {
    padding: 24px;
    background: #ffffff;
    border-radius: 0 0 16px 16px;
    -webkit-overflow-scrolling: touch;
    // max-height: calc(100vh - 134px);
    // overflow: auto;
    @media screen and (device-aspect-ratio: 40/71) {
      // max-height: calc(400px - 134px);
    }
    textarea {
      background: #dadada;
      border-radius: 2px;
      border: 1px solid #dadada;
      width: 100%;
      height: 100px;
      padding: 6px;
    }
    input {
      background: #dadada;
      border-radius: 2px;
      padding: 6px;
    }
    button[type="submit"] {
      background: #ffc34c;
      // border: 1px solid #A6A6A9;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      padding: 0 25px;
      border: 0;
      -webkit-appearance: none;
      justify-content: flex-start;
    }
    h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      /* Global / Charcoal Gray */

      color: #181830;
      margin-bottom: 32px;
    }
    img {
      float: left;
      padding: 0 24px 0 0;
    }
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      color: #404043;
      margin-bottom: 24px;
    }
    .bullet_point {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        position: relative;
        padding: 5px 0;
        .hilighted {
          position: absolute;
          width: calc(100% + 48px);
          height: 100%;
          top: 50%;
          transform: translateY(-50%);
          left: -24px;
          z-index: -1;
          // padding:  4px 0;
        }
        &.very_good {
          span {
            background: #049f50;
          }
        }
        &.good {
          span {
            background: #85bb2f;
          }
        }
        &.medium {
          span {
            background: rgb(212, 221, 92);
          }
        }
        &.bad {
          span {
            background: #ee8100;
          }
        }
        &.very_bad {
          span {
            background: #e63e11;
          }
        }
        span {
          width: 20px;
          height: 20px;
          left: 24px;
          top: 382px;

          /* Grades / Very Good */

          display: inline-flex;
          border-radius: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}

.explanation_fees {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 80%;
  align-items: flex-end;
  position: relative;
  margin: 0 auto;
  .bareme {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 12px 0 0;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: calc(50% + 14px);
      height: 60%;
      background: #000000;
      transform: translateX(-50%) translateY(-50%);
      width: 1px;
    }
    &:after {
      content: "";
      position: absolute;
      height: 50%;
      background: url("../assets/images/triangle.svg") no-repeat;
      width: 20px;
      height: 20px;
      top: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%) rotate(180deg);
    }
    span {
      &:first-child {
        position: relative;
        top: 14px;
      }
      &:last-child {
        position: relative;
        top: -6px;
      }
    }
  }
}
